// Funzione che in base all'appartenenza dei gruppi di un utente, restituisce la lista di impianti che può visualizzare
// Es: Se un utente ha accesso ad un aggregatore, di quell'aggregatore ad un solo quartiere e di quel quartiere ad un gruppo ristretto di edifici, allora dovrà visualizzare solo quegli edifici.
// N.B: Questo non si applica ad esempio sulle visualizzazioni come la pagina analytics per aggregatore e quartiere dove l'utente facendo parte anche del gruppo aggregatore e quartiere ha accesso a tutti i dati cumulati
export const filterPlantsByPlantgroups = (plants = [], userGroups = []) => {
	// Considero solo i gruppi che sono stati creati da un edificio
	const groupsCreatedFromPlants = userGroups.filter(
		(group) => group.metadata?.createdFrom === "plant",
	);

  if(groupsCreatedFromPlants?.length === 0) {
    return plants
  }
	// Costruisco la lista unica degli uuid degli edifici che posso visualizzare
	const plantsIds = groupsCreatedFromPlants.flatMap((group) =>
		group.resources
			.filter((resource) => resource.startsWith("/plants/"))
			.map((resource) => resource.replace("/plants/", "")),
	);

	const availablePlants = [...new Set(plantsIds)];

	return plants.filter(plant => availablePlants.includes(plant.uuid));
};