import { filterPlantsByPlantgroups } from "@/utils/plants"
import api from "@micmnt/apis";
import log from "@pelv/frontlog";
import { createSlice } from "@reduxjs/toolkit";

// funzione che torna la percentuale passati 2 valori
const percentage = (partialValue, totalValue) => {
	const results = (100 * partialValue) / totalValue;
	return results.toFixed(0);
};


// coordinate dell'Italia sotto forma di array di array dove ogni punto a latitudine e longitudine
// minimo 3 punti (per avere una figura chiusa)
// centro dell'italia ha le seguenti coordinate = [43, 12] ([LNG,LAT])
const geoItaly = [
	[6.240234374999999, 36.491973470593685],
	[19.423828125, 36.491973470593685],
	[19.423828125, 47.30903424774781],
	[6.240234374999999, 47.30903424774781],
	[6.240234374999999, 36.491973470593685],
];

// array con un valore numero per gli stati
const statusToNumber = {
	ok: 0,
	maintenance: 1,
	warning: 1,
	error: 2,
};
// funzione che ordina array impianti normalizzati per stato
const statusSort = () => {
	// se c'è da fare qualcosa in più va qui
	return (a, b) => {
		// considerando che vogliono vedere prima gli impianti in errore
		// e che questi hanno status.general === 'error', assegno un valore ad ogni parola
		const x = statusToNumber[a.status.general];
		const y = statusToNumber[b.status.general];
		const result = x > y ? -1 : x < y ? 1 : 0;
		return result;
	};
};

// stato dello slice, tutte le variabili principali
const initialState = {
	getHasError: null,
	filters: {
		address: "",
	},
	plants: [],
	normalizedPlants: ["-"],
	aggregators: [],
	districts: [],
	statusBar: {
		total: 0,
		error: {
			value: 0,
			percentage: 0,
		},
		warning: {
			value: 0,
			percentage: 0,
		},
		good: {
			value: 0,
			percentage: 0,
		},
	},
	plantsType: ["Tutti", "Smart Home", "Smart Building"],
};

// SLICE = state + reducers
const slice = createSlice({
	name: "maps",
	initialState,
	reducers: {
		setNormalizedPlants(state, action) {
			const {
				plants,
				hasError,
				aggregatorsList = null,
				districtsList = null,
			} = action.payload;
			log({
				text: "plants payload => ",
				variable: plants,
				tag: "map-slice",
			});
			if (hasError) {
				state.getHasError = hasError;
			} else {
				// state.normalizedPlants = [
				//  ...Array(16).fill(basicNormPlant0),
				//  ...Array(4).fill(basicNormPlant2),
				//  ...Array(1).fill(basicNormPlant1),
				// ]
				// mi preparo il nuovo array di impianti
				const newPlants = [];
				plants.forEach((plant) => {
					const toPush = {
						uuid: plant.uuid,
						name: plant.name,
						plantType: plant.metadata.plantType || "",
						powerPeak: plant.metadata.peakPower || "0",
						aggregator:
							aggregatorsList?.find(
								(el) => el.uuid === plant?.metadata?.aggregatorId,
							)?.name || "",
						district:
							districtsList?.find(
								(el) => el.uuid === plant?.metadata?.districtId,
							)?.name || "",
						production: "59",
						pr: "0.5",
						irradiation: "10",
						moduleTemp: "15",
						generalTemp: "15",
						status: {
							general: plant.status.general || null,
							climate: plant.status.climate || null,
							performance: plant.status.performance || null,
							online: plant.status.online || null,
						},
						location: plant.location,
					};
					newPlants.push(toPush);
				});
				// assegno il nuovo array alla mia variabile di stato
				state.normalizedPlants = newPlants;
				// Assegno gli array di aggregatori e di quartieri
				state.aggregators = aggregatorsList || [];
				state.districts = districtsList || [];
				// faccio il sort in base allo status
				state.normalizedPlants.sort(statusSort());
				// calcolo i valori per lo statusBar
				if (state.normalizedPlants.length > 0) {
					state.statusBar.total = state.normalizedPlants.length;
					state.statusBar.error.value = state.normalizedPlants.filter(
						(el) => el.status.general === "error",
					).length;
					state.statusBar.warning.value = state.normalizedPlants.filter(
						(el) => el.status.general === "maintenance",
					).length;
					state.statusBar.warning.value = state.normalizedPlants.filter(
						(el) => el.status.general === "warning",
					).length;
					state.statusBar.good.value = state.normalizedPlants.filter(
						(el) => el.status.general === "ok",
					).length;
					// calcolo le percentuali
					state.statusBar.error.percentage = percentage(
						state.statusBar.error.value,
						state.statusBar.total,
					);
					state.statusBar.warning.percentage = percentage(
						state.statusBar.warning.value,
						state.statusBar.total,
					);
					state.statusBar.good.percentage = percentage(
						state.statusBar.good.value,
						state.statusBar.total,
					);
				}
			}
		},
		resetMapsViewToDefault(state) {
			state.getHasError = null;
			state.filters = { address: "" };
			state.plants = [];
			state.normalizedPlants = ["-"];
			state.aggregators = [];
			state.districts = [];
			state.statusBar = {
				total: 0,
				error: {
					value: 0,
					percentage: 0,
				},
				warning: {
					value: 0,
					percentage: 0,
				},
				good: {
					value: 0,
					percentage: 0,
				},
			};
			state.plantsType = ["Tutti", "Smart Home", "Smart Building"];
			state = { ...initialState };
		},
	},
});

export const reducer = slice.reducer;

// Se non vengono passati dati di geolocalizzazione imposto l'italia
// puoi vedre il quadrato dell'italia qui: https://geojson.io/#map=6/42.569/12.415
export const getPlants =
	({
		canModify = true,
		q = null,
		coordinates = geoItaly,
		filter = null,
		user = null,
	}) =>
	async (dispatch) => {
		const params = {};

		if (q) {
			params.q = JSON.stringify(q);
		} else {
			params.coordinates = JSON.stringify(coordinates);
		}

		if (filter) {
			params.filter = filter;
		}

		let hasError = null;
		let aggregatorsList = null;
		let districtsList = null;

		const { data: plants, error } = await api.get({
			savedUrl: "plantsMap",
			params,
		});

		if (error) {
			hasError = "generic";
		}

		if (!error && canModify) {
			const { data: aggregators, error: aggregatorsError } = await api.get({
				savedUrl: "aggregatorsList",
			});

			if (aggregatorsError) return;

			const { data: districts, error: districtsError } = await api.get({
				savedUrl: "districtsList",
			});

			if (districtsError) return;

			aggregatorsList = aggregators;
			districtsList = districts;
		}

		const filteredPlants = filterPlantsByPlantgroups(
			plants,
			user?.groups ?? [],
		);

		dispatch(
			slice.actions.setNormalizedPlants({
				plants: filteredPlants,
				hasError,
				aggregatorsList,
				districtsList,
			}),
		);
	};

// funzione che azzera la view mappe
export const resetMapsView = () => (dispatch) => {
	dispatch(slice.actions.resetMapsViewToDefault());
};
