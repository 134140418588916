// oggetto con le configurazioni generali della piattaforma
export const configuration = {
	environment:
		import.meta.env.MODE || import.meta.env.VITE_ENV || "development",
	apiUrl: import.meta.env.VITE_API_BASE_URL || 'https://api.test.enea.apio.network',
	// apiUrl: import.meta.env.VITE_API_BASE_URL || "https://www.smarthome.enea.it/api",
	projectId:
		import.meta.env.VITE_PROJECTID || "43c4bb12-5a38-4ed8-b704-5be64310012a",
	authPlatformLogout:
		import.meta.env.VITE_AUTH_PLATFORM_LOGOUT_URL || "/logout",
	authPlatformLogin: import.meta.env.VITE_AUTH_PLATFORM_LOGIN_URL || "/login",
	dashboardUrl:
		import.meta.env.VITE_DASHBOARD_BASE_URL ||
		"https://enea-dashboard.netlify.app",
	dataloggerAddress:
		import.meta.env.VITE_DATALOGGER_ADDRESS ||
		"broker.test.venera.apio.network",
	dataloggerUsername:
		import.meta.env.VITE_DATALOGGER_USERNAME || "/projects/venera/nodes/",
	dataloggerUsernameZpass:
		import.meta.env.VITE_DATALOGGER_USERNAME_ZPASS || "_projects_venera_nodes_",
	dataloggerTopic:
		import.meta.env.VITE_DATALOGGER_TOPIC ||
		"apio/core/projects/venera/telemetry/uplink",
	logTag: import.meta.env.VITE_APIO_LOG || null,
	googleMapsApiKey:
		import.meta.env.VITE_GOOGLE_MAPS_API_KEY ||
		"AIzaSyBN0sLe2Nmb6zo9DdRX-kRHdRpOC2FAPqg",
	webSocket:
		import.meta.env.VITE_WEB_SOCKET || "wss://ws.test.venera.apio.network",
	sentry: {
		dsn: "https://0f1cc5a77c3e447bb59a1d4a69138c0f@sentry.apio.cloud/46",
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
	},
};

export const clientPermissions = [
	{
		value: "enea.aggregators.section",
		name: "Visibilità sezione Aggregatori",
		description: "Visibilità della sezione degli aggregatori",
		defaultRoute: "/app/overview/analytics",
		acceptedRoutes: ["/app/overview/analytics"],
	},
	{
		value: "enea.aggregators.read",
		name: "Visualizzazione Aggregatori",
		description:
			"Visualizzazione degli aggregatori e delle loro caratteristiche",
		defaultRoute: "/app/overview/analytics",
		acceptedRoutes: ["/app/overview/analytics"],
	},
	{
		value: "enea.aggregators.write",
		name: "Gestione Aggregatori",
		description:
			"Visualizzazione, creazione, modifica ed eliminazione degli aggregatori",
		defaultRoute: "/app/management/aggregators",
		acceptedRoutes: ["/app/overview/analytics", "/app/management/aggregators"],
	},
	{
		value: "enea.nodes.section",
		name: "Visibilità sezione Datalogger",
		description: "Visibilità della sezione datalogger",
		defaultRoute: "/app/management/devices",
		acceptedRoutes: ["/app/management/devices"],
	},
	{
		value: "enea.nodes.read",
		name: "Visualizzazione Dataloggers",
		description: "Visualizzazione dei dataloggers e delle loro caratteristiche",
		defaultRoute: "/app/management/devices",
		acceptedRoutes: ["/app/management/devices"],
	},
	{
		value: "enea.nodes.write",
		name: "Gestione Dataloggers",
		description:
			"Visualizzazione, creazione, modifica ed eliminazione dei dataloggers ",
		defaultRoute: "/app/management/devices",
		acceptedRoutes: ["/app/management/devices"],
	},
	{
		value: "enea.buildings.section",
		name: "Visibilità sezione Edifici",
		description: "Visibilità della sezione edifici",
		defaultRoute: "/app/view/plant",
		acceptedRoutes: ["/app/view/plant"],
	},
	{
		value: "enea.buildings.read",
		name: "Visualizzazione Edifici",
		description: "Visualizzazione degli edifici e delle loro caratteristiche",
		defaultRoute: "/app/view/plant",
		acceptedRoutes: ["/app/view/plant"],
	},
	{
		value: "enea.buildings.write",
		name: "Gestione Edifici",
		description:
			"Visualizzazione, creazione, modifica ed eliminazione degli edifici",
		defaultRoute: "/app/management/plants",
		acceptedRoutes: ["/app/management/plants"],
	},
	{
		value: "enea.map.section",
		name: "Visibilità Sezione Mappa Edifici",
		description: "Visibilità della sezione Mappa degli edifici",
		defaultRoute: "/app/overview/maps",
		acceptedRoutes: ["/app/overview/maps"],
	},
	{
		value: "enea.users.section",
		name: "Visibilità sezione utenti",
		description: "Visibilità della sezione utenti",
		defaultRoute: "/app/management/users",
		acceptedRoutes: ["/app/management/users"],
	},
	{
		value: "enea.users.read",
		name: "Visualizzazione Utenti",
		description: "Visualizzazione degli utenti e delle loro caratteristiche",
		defaultRoute: "/app/management/users",
		acceptedRoutes: ["/app/management/users"],
	},
	{
		value: "enea.users.write",
		name: "Gestione Utenti",
		description:
			"Visualizzazione, creazione, modifica ed eliminazione degli utenti",
		defaultRoute: "/app/management/users",
		acceptedRoutes: ["/app/management/users"],
	},
	{
		value: "enea.roles.section",
		name: "Visibilità sezione ruoli",
		description: "Visibilità della sezione dei ruoli",
		defaultRoute: "/app/management/roles",
		acceptedRoutes: ["/app/management/roles"],
	},
	{
		value: "enea.roles.read",
		name: "Visualizzazione ruoli",
		description: "Visualizzazione dei ruoli e delle loro caratteristiche",
		defaultRoute: "/app/management/roles",
		acceptedRoutes: ["/app/management/roles"],
	},
	{
		value: "enea.roles.write",
		name: "Gestione ruoli",
		description:
			"Visualizzazione, creazione, modifica ed eliminazione dei ruoli",
		defaultRoute: "/app/management/roles",
		acceptedRoutes: ["/app/management/roles"],
	},
];

export const clientServices = [
	{
		value: "enea.services.anomalies",
		name: "Ricezione Notifiche Anomalie",
		description: "Ricezione delle notifiche dovute ad anomalie",
		defaultRoute: "/app/overview/anomalies",
		acceptedRoutes: ["/app/overview/anomalies"],
	},
	{
		value: "enea.services.maps",
		name: "Visualizzazione Mappa",
		description: "Visualizzazione della mappa degli edifici",
		defaultRoute: "/app/overview/maps",
		acceptedRoutes: ["/app/overview/maps"],
	},
	{
		value: "enea.services.logs",
		name: "Gestione Logs",
		description: "Creazione, visualizzazione e scaricamento dei logs",
		defaultRoute: "/app/overview/analytics",
		acceptedRoutes: ["/app/overview/analytics"],
	},
];

// variabile con i permessi backend in base ad i permessi frontend
// default full backend permission
export const allBackendPermissions = {
	"enea.aggregators.section": [
		"enea.smarthome.aggregators.read",
		"enea.smarthome.districts.read",
		"apio.core.plants.read",
		"apio.core.nodes.read",
		"apio.core.nodetypes.read",
		"apio.core.devices.read",
		"apio.core.devicetypes.read",
		"apio.core.assets.read",
		"apio.core.assettypes.read",
		"apio.telemetry.data.read",
	],
	"enea.aggregators.write": [
		"enea.smarthome.aggregators.write",
		"enea.smarthome.districts.write",
	],
	"enea.aggregators.read": [
		"enea.smarthome.aggregators.read",
		"enea.smarthome.districts.read",
	],
	"enea.nodes.section": [
		"apio.core.devices.read",
		"apio.core.nodetypes.read",
		"apio.core.nodes.read",
	],
	"enea.nodes.read": [
		"apio.core.plants.read",
		"apio.core.devices.read",
		"apio.core.devicetypes.read",
		"apio.core.nodetypes.read",
		"apio.core.nodes.read",
		"apio.telemetry.data.read",
		"apio.authentication.apikeys.read",
		"apio.telemetry.data.write",
		"apio.authentication.aclrules.read",
		"apio.authentication.roles.read",
		"apio.authentication.groups.read",
	],
	"enea.nodes.write": [
		"apio.core.devices.write",
		"apio.core.devicetypes.write",
		"apio.core.nodes.write",
		"apio.authentication.apikeys.write",
		"apio.telemetry.data.write",
		"apio.authentication.groups.write",
	],
	"enea.buildings.section": ["apio.core.plants.read"],
	"enea.buildings.read": [
		"apio.core.plants.read",
		"apio.core.nodes.read",
		"apio.core.devices.read",
		"apio.core.devicetypes.read",
		"apio.core.nodetypes.read",
		"apio.core.assettypes.read",
		"apio.core.assets.read",
		"apio.authentication.aclrules.read",
		"apio.authentication.roles.read",
		"apio.authentication.groups.read",
		"enea.smarthome.aggregators.read",
		"enea.smarthome.districts.read",
	],
	"enea.buildings.write": [
		"apio.core.plants.write",
		"apio.core.nodes.write",
		"apio.core.devices.write",
		"apio.core.assettypes.write",
		"apio.core.assets.write",
		"apio.authentication.groups.write",
		"enea.smarthome.aggregators.write",
		"enea.smarthome.districts.write",
	],
	"enea.users.section": [
		"apio.authentication.accounts.read",
		"apio.authentication.roles.read",
		"apio.authentication.aclrules.read",
		"apio.authentication.groupmemberships.read",
		"apio.authentication.groups.read",
		"apio.core.plants.read",
		"apio.core.nodes.read",
	],
	"enea.users.read": [
		"apio.authentication.accounts.read",
		"apio.authentication.aclrules.read",
		"apio.authentication.roles.read",
		"apio.authentication.groupmemberships.read",
		"apio.authentication.groups.read",
	],
	"enea.users.write": [
		"apio.authentication.aclrules.write",
		"apio.authentication.groupmemberships.write",
		"apio.authentication.groups.write",
	],
	"enea.roles.section": ["apio.authentication.roles.read"],
	"enea.roles.read": ["apio.authentication.roles.read"],
	"enea.roles.write": [
		"apio.authentication.roles.read",
		"apio.authentication.roles.write",
	],
	"enea.services.anomalies": [
		"apio.core.anomalies.read",
		"apio.core.anomalies.write",
		"apio.core.anomalyconfigurations.read",
		"apio.notifications.email.receive",
		"apio.notifications.websocket.receive",
	],
	"enea.services.maps": [
		"apio.core.plants.read",
		"apio.core.nodes.read",
		"apio.core.devices.read",
		"apio.core.devicetypes.read",
		"apio.core.assets.read",
		"apio.core.assettypes.read",
		"apio.core.nodetypes.read",
		"apio.telemetry.data.read",
		"enea.smarthome.aggregators.read",
		"enea.smarthome.districts.read",
	],
  "enea.services.logs": [
    "enea.smarthome.aggregators.read",
		"enea.smarthome.districts.read",
		"apio.core.plants.read",
		"apio.core.nodes.read",
		"apio.core.nodetypes.read",
		"apio.core.devices.read",
		"apio.core.devicetypes.read",
		"apio.core.assets.read",
		"apio.core.assettypes.read",
		"apio.telemetry.data.read",
  ]
};

// funzione che torna i backend permissions
export const returnBackendPermissions = (appPermissions) => {
	// mi sistemo i permessi backend
	const thisBackPerms = ["apio.core.projects.read"];
	appPermissions.forEach((perm) => {
		if (allBackendPermissions[perm]) {
			thisBackPerms.push(...allBackendPermissions[perm]);
		}
	});
	// elimina i duplicati e ritorno
	return [...new Set(thisBackPerms)];
};
